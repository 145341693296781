import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "select"];

  connect() {
    this.setOption =
      this.selectTarget.options[this.selectTarget.selectedIndex].text;
    if (
      this.setOption == "Other" ||
      this.setOption == "GHG emission reduction or sequestration"
    ) {
      this.showAdditionalField();
    }
  }

  setSelection() {
    this.selectedOption =
      this.selectTarget.options[this.selectTarget.selectedIndex].text;
    if (
      this.selectedOption == "Other" ||
      this.selectedOption == "GHG emission reduction or sequestration"
    ) {
      this.showAdditionalField();
    } else {
      this.inputTarget.classList.add("d-none");
    }
  }

  showAdditionalField() {
    this.inputTarget.classList.remove("d-none");
  }
}
