import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["color", "result"];

  connect() {
    this.colorAllCountries();
  }

  colorAllCountries() {
    // make sure countries are colored when initializing the map

    this.countryCollection = JSON.parse(
      this.element.getAttribute("data-all-countries"),
    );
    this.countryArrayForGoal = this.countryCollection || [];
    this.addColor();
  }

  setSdgGoal(event) {
    // adapt coloring of coutries whe selecting a SDG and show impacts

    this.countryCollection = JSON.parse(
      this.element.getAttribute("data-country-by-sdg"),
    );
    this.selectedGoal = event.target.value;

    if (this.selectedGoal > 0) {
      this.countryArrayForGoal =
        this.countryCollection[this.selectedGoal] || [];

      this.addColor();
    } else {
      this.colorAllCountries();
    }

    this.toggleResultsSection();
  }

  addColor() {
    if (this.previousColoredCountries) {
      this.cleanUp();
    }

    const countryElements = this.countryArrayForGoal.map((country) => {
      return this.colorTarget.getElementsByClassName(country);
    });

    countryElements.forEach((elements) => {
      for (let j = 0; j < elements.length; j++) {
        elements[j].setAttribute("fill", "#1d71b8");
      }
    });
    this.previousColoredCountries = countryElements;
  }

  cleanUp() {
    for (let i = 0; i < this.previousColoredCountries.length; i++) {
      for (let j = 0; j < this.previousColoredCountries[i].length; j++) {
        this.previousColoredCountries[i][j].setAttribute("fill", "#ececec");
      }
    }
  }

  toggleResultsSection() {
    // show table of impacts for selected SDG and hide it if not applicable

    if (this.visibleDiv) {
      this.visibleDiv.classList.add("d-none");
    }

    this.resultTargets.map((div) => {
      if (div.id == this.selectedGoal) {
        div.classList.remove("d-none");
        this.visibleDiv = div;
      }
    });
  }
}
