import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select"];

  setImpactType(event) {
    this.selectedImpactType = event.target.value;
  }

  filterIndicatorOptions() {
    this.selectOptions = JSON.parse(this.data.get("selectOptions"));
    this.filteredIndicators = this.selectOptions[this.selectedImpactType];

    var i;
    var optionsArray = ["<option value>Please select an indicator</option>"];
    for (i = 0; i < this.filteredIndicators.length; i++) {
      optionsArray.push(
        '<option value="' +
          this.filteredIndicators[i][0] +
          '">' +
          this.filteredIndicators[i][1] +
          "</option>",
      );
    }

    this.selectTarget.innerHTML = optionsArray.join(" ");
    this.highlightIndicatorSelect();
  }

  highlightIndicatorSelect() {
    var selectTarget = this.selectTarget;
    selectTarget.classList.add("highlight");

    setTimeout(function () {
      selectTarget.classList.remove("highlight");
    }, 2000);
  }
}
