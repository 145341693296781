import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["title", "description", "unit", "exist"];

  connect() {
    let existingIndicator =
      this.existTarget.querySelector("option:checked").value;

    if (existingIndicator) {
      this.selectedIndicator = existingIndicator;
      this.showUnit();
    }
  }

  setIndicator(event) {
    this.selectedIndicator = event.target.value;
  }

  showUnit() {
    this.unitCollection = JSON.parse(this.data.get("unit-collection"));
    this.unit = this.unitCollection[this.selectedIndicator];
    this.unitTarget.innerHTML = this.unit;
  }

  showExplanation() {
    this.titleCollection = JSON.parse(this.data.get("title-collection"));
    this.title = this.titleCollection[this.selectedIndicator];
    this.titlePlaceholder = "Please select an indicator first";

    this.descriptionCollection = JSON.parse(
      this.data.get("description-collection"),
    );
    this.description = this.descriptionCollection[this.selectedIndicator];
    this.descriptionPlaceholder = "";

    if (this.existTarget.value == "") {
      this.titleTarget.innerHTML = this.titlePlaceholder;
      this.descriptionTarget.innerHTML = this.descriptionPlaceholder;
    } else {
      this.titleTarget.innerHTML = this.title;
      this.descriptionTarget.innerHTML = this.description;
    }
  }
}
